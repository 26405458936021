import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

import pdfGDPR from '../../../static/assets/terms/Compute-Software-GDPR-Provisions-Addendum.pdf'
import pdfSecurityFramework from '../../../static/assets/terms/Compute-Software-Security-Framework.pdf'
import pdfServiceLevelStandards from '../../../static/assets/terms/Compute-Software-Service-Level-Standards.pdf'
import pdfServicesTermsAndConditions from '../../../static/assets/terms/Compute-Software-Services-Terms-and-Conditions.pdf'
import pdfSubprocessors from '../../../static/assets/terms/Compute-Software-Subprocessors.pdf'
import pdfSupportServices from '../../../static/assets/terms/Compute-Software-Support-Services.pdf'

export default function Terms() {
  return (
    <Layout>
      <SEO title="Terms - Compute Software"/>
      <Helmet></Helmet>
      <section className="terms-hero sub-hero jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">Terms</h1>
          <p className="lead">This site presents various legal resources. We offer our services with a commitment around security, privacy, uptime, and support.</p>
        </div>
      </section>
      <section className="terms-content sub-general-content">
        <div className="container">
          <div className="row">
            <div className="terms col-lg-12">
              <ul className="terms-listing">
                <li>
                  <h3>Compute Software Services Terms and Conditions</h3>
                  <p>The terms that govern your subscriptions and customer success engagements.</p>
                  <a href={pdfServicesTermsAndConditions} target="_blank" rel="noreferrer">View Terms and Conditions</a>
                </li>
                <li>
                  <h3>Customer Support Services Policy</h3>
                  <p>Compute Software's baseline tech support commitment to you related to the services.</p>
                  <a href={pdfSupportServices} target="_blank" rel="noreferrer">View Policy</a>
                </li>
                <li>
                  <h3>Service Level Standards</h3>
                  <p>Compute Software's baseline commitment to you regarding uptime of the service.</p>
                  <a href={pdfServiceLevelStandards} target="_blank" rel="noreferrer">View Standards</a>
                </li>
                <li>
                  <h3>Security Framework</h3>
                  <p>Compute Software's baseline commitment to you regarding the security measures in place to protect your data.</p>
                  <a href={pdfSecurityFramework} target="_blank" rel="noreferrer">View Framework</a>
                </li>
                <li>
                  <h3>Third-Party Service Providers (“Subprocessors”)</h3>
                  <p>The third-party service providers retained to enable a portion of the services.</p>
                  <a href={pdfSubprocessors} target="_blank" rel="noreferrer">View Providers</a>
                </li>
                <li>
                  <h3>Privacy Policy</h3>
                  <p>Compute Software’s privacy policy, covering personal information provided to us through the corporate website and services.</p>
                  <a href="/privacy-policy">View Policy</a>
                </li>
                <li>
                  <h3>Privacy Shield Privacy Policy</h3>
                  <p>Compute Software’s Privacy Shield privacy policy, covering personal information provided to us from the EU, United Kingdom, and Switzerland.</p>
                  <a href="/terms/privacy-shield-privacy-policy">View Policy</a>
                </li>
                <li>
                  <h3>GDPR Provisions</h3>
                  <p>These provisions represent our contractual commitment to our customers regarding compliance with the GDPR requirements.</p>
                  <a href={pdfGDPR} target="_blank" rel="noreferrer">View GDPR Provisions</a>
                </li>
                <li>
                  <h3>Website Terms of Use</h3>
                  <p>The terms that govern using our corporate website.</p>
                  <a href="/terms/website-terms-of-use">View Terms</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
